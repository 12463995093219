import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { red } from '@mui/material/colors';
import ReactDOM from "react-dom";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useState, useEffect, useRef } from "react";
import Grid from '@mui/material/Grid';

function MediaCard1() {
  return (
    <Card sx={{ minWidth:325, maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image="/hyyp_rewards.png"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          HYYP
        </Typography>
        <Typography variant="body2" color="text.secondary">
        </Typography>
      </CardContent>
      <CardActions>
        <Button href="https://gethyyp.com" size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
}

function MediaCard2() {
  return (
    <Card sx={{ minWidth:325,  maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image="/group.png"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Human Elemenz LLC.
        </Typography>
        <Typography variant="body2" color="text.secondary">
        </Typography>
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
  );
}


function MediaCard3() {
  return (
    <Card sx={{ minWidth:325, maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image="/contact.png"
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Contacts
        </Typography>
        <Typography variant="body2" color="text.secondary">
        </Typography>
      </CardContent>
      <CardActions>
        <Button href="mailto://info@humanelemenz.com" size="small">E-Mail</Button>
      </CardActions>
    </Card>
  );
}



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderWidth:'0',
  boxShadow:'none',
}));

function ResponsiveStack() {
  return (
        <div>
            <Stack
              direction={{ xs: 'column', sm: 'column' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Item >
                <MediaCard1></MediaCard1>
              </Item>
              <Item >
                <MediaCard2></MediaCard2>
              </Item>
              <Item >
                <MediaCard3></MediaCard3>
                </Item>
            </Stack>
          </div>

  );
}




const pages = ['Products', 'About', 'Contact'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {    
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    var tmp;
    if(e.target !== undefined ) {
      tmp = e.target.innerHTML;
      //console.log(e.target.innerHTML);
    } else {
      tmp = e;
      //console.log(e);
    }
    props.goTo(tmp);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (e) => {
    console.log(e);
    setAnchorElUser(null);
  };

  return (
      <Container  maxWidth="xl">
        <Toolbar   disableGutters>
          <Diversity3Icon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 100,
              letterSpacing: '.01rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Human Elemenz LLC
          </Typography>

          <Box sx={{ flexGrow: 1,  display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Diversity3Icon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h7"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 100,
              letterSpacing: '.01rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Human Elemenz LLC
          </Typography>
          <Box  justifyContent="flex-end"  sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleCloseNavMenu(page)}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box display="none" sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton 
              onClick={handleOpenUserMenu} 
              sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} 
                    onClick={() => handleCloseUserMenu(setting)} >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
  );
}



function appFooter() {
  
}

function App() {
const [light, setLight] = React.useState(true);  
const myRef1 = useRef(null);
const myRef2 = useRef(null);
const myRef3 = useRef(null);

const goTo1 = () => myRef1.current.scrollIntoView() 
const goTo2 = () => myRef2.current.scrollIntoView() 
const goTo3 = () => myRef3.current.scrollIntoView() 

function gotoFunc(p) {
  if(p === 'Products') {
    goTo1();
  console.log(1);
  }
  if(p === 'About') {
    goTo2();
  console.log(2);
  }
  if(p === 'Contact') {
    goTo3();
  console.log(3);
  }
}

  return (
    <div className="App">
      <Container>
        <Box>
          <ResponsiveAppBar goTo={gotoFunc}></ResponsiveAppBar>
        </Box>
        <Box>
        <div>
            <Stack
              direction={{ xs: 'column', sm: 'column' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Box ref={myRef1}>
                <Stack  justifyContent="flex-end" 
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                <Item>                  
                <Typography variant="h2">
                  Products
                </Typography>
                <Typography variant="h6">
                  Hyyp is an online loyalty program and CRM solution for POS and eCommerce systems. Earn more customer loyalty with Hyyp's full stack of loyalty management products.
                </Typography>
                </Item>
                <Item>
                <MediaCard1></MediaCard1>
                </Item>
                </Stack>
              </Box>
              <Box ref={myRef2}>
                <Stack  
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                <Item sx= {{display:{xs:'none',sm: 'none', md: 'block'}}}>
                  <MediaCard2></MediaCard2>
                </Item>
                <Item>                   
                  <Typography variant="h2">
                  About Us
                  </Typography>
                <Typography variant="h6">
                  At Human Elemenz our mission is to keep the focus on the people, at the intersection of Humans and Technology. 
                  Our vision is to ensure each and every interaction is positive and of equal value to both the customer and the business.  
                </Typography>
                </Item>
                <Item sx={{display:{xs:'block',sm:'none',md:'none'}}}>
                  <MediaCard2></MediaCard2>
                </Item>
                </Stack>
              </Box>
              <Box ref={myRef3}>
                <Stack  justifyContent="flex-end" 
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                <Item>                  
                <Typography variant="h2">
                  Contact Us
                </Typography>
                <Typography variant="h6">
                  Please reach out to us at info@humanelemenz.com
                </Typography>
                </Item>
                <Item>
                <MediaCard3></MediaCard3>
                </Item>
                </Stack>
              </Box>
            </Stack>
          </div>                
        </Box>
      </Container>
      <Box sx={{ minHeight:200, backgroundColor: 'gray' }} >
          <Box sx={{pt:10}}>
                    <Typography>                      
                      Human Elemenz LLC. 
                    </Typography>
                    <Typography>                      
                      ©2023 All Rights Reserved. humanelemenz.com
                    </Typography>
          </Box>
      </Box>
    </div>
  );
}

export default App;
